import React from 'react';
import { getEventDateRange, groupEventsByMonth } from './../util';
import { Link } from 'gatsby';
import { Event } from './Event';
import { navigateToEventSectionDetails } from '../helpers/eventSections.helper';
import {
  faCheckSquare,
  faFile,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { EventTypes } from '../pages/events';
import { startOfDay } from 'date-fns';

type EventCardTypePillProps = {
  type: EventTypes;
};

export const EventCardTypePill = (props: EventCardTypePillProps) => {
  const classNames = classnames({
    'rounded-full px-3 py-1 text-sm sans-serif': true,
    'bg-blue-200 text-primary': props.type === EventTypes.ONE_DAY_CONFERENCE,
    'bg-primary text-blue-100': props.type === EventTypes.INSTITUTE,
    'bg-blue-400 text-blue-50':
      props.type === EventTypes.SEMINAR_SERIES ||
      props.type === EventTypes.COURSES
  });

  return <span className={classNames}>{props.type}</span>;
};

interface EventListProps {
  events: Event[];
}

const EventList = (props: EventListProps) => {
  const eventsByMonth = groupEventsByMonth(props.events);
  return (
    <div className="">
      {eventsByMonth.map((eventMonth, i) => (
        <div key={i}>
          <h3 className="mb-4 text-primary font-bold text-lg border-b-2 border-primary">
            {eventMonth.month}
          </h3>
          {eventMonth.events.map((event: Event, i) => (
            <Link to={navigateToEventSectionDetails(event)} key={i}>
              <div className="mb-6">
                <p className="uppercase font-semibold text-sm text-gray-500 mb-2">
                  <span>
                    {getEventDateRange(
                      new Date(event.startDate),
                      new Date(event.endDate)
                    )}
                  </span>
                </p>
                <p className="mb-2 font-semibold text-gray-800 hover:text-primary hover:underline">
                  {event.title}
                </p>
                <EventCardTypePill type={(event as any).eventType} />
              </div>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

export default EventList;
